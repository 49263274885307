<template>
    <div class="bg-green-200">
        
    </div>
</template>
<script>

// import clientCard from '@/components/client-card';
// import Input from '@/components/input'
// import packageCard from '@/components/package-card';

export default {
    name:"create-case-form",
    components:{
        // clientCard,
        // Input,
        // packageCard
    },
    data() {
        return {
        
        }
    },
    methods:{
    }
}
</script>
<style scoped>

</style>